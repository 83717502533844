// needed for positioning by bootstrap dropdowns, popover and tooltip module
// eslint-disable-next-line import/no-unassigned-import
import '@popperjs/core';

import { SiteHelper } from './modules/sitehelper';

// eslint-disable-next-line import/no-unassigned-import
import './modules/megamenu';

// eslint-disable-next-line import/no-unassigned-import
import './modules/usercentrics-googlemaps';
// eslint-disable-next-line import/no-unassigned-import
import './modules/usercentrics-youtube';

// eslint-disable-next-line import/no-unassigned-import, @typescript-eslint/no-unused-vars
import * as bootstrap from 'bootstrap';

declare global {
    interface Window {
        siteHelper: SiteHelper,
        isInitialized: boolean,
        dataLayer: object[],
        ucDps: UsercentricsDataProcessingServices
    }
}

window.bootstrap = bootstrap;
window.isInitialized = false;

// dynamic loading
async function promoBoxComponentLoader (): Promise<void> {
    const promoBoxes = document.querySelectorAll('.js-promocopy');
    if (promoBoxes.length > 0) {
        const module = await import(/* webpackChunkName: "promobox" */ './modules/promo-box');
        module.promoBoxComponent().handleCopyClicks('.js-promocopy');
    }
}

async function fcSuggestLoader (): Promise<void> {
    const fcSuggests = document.querySelectorAll('.js-fcsuggest');
    if (fcSuggests.length > 0) {
        const module = await import(/* webpackChunkName: "fcsuggest" */ './modules/fcibe-suggest');
        [].forEach.call(fcSuggests, function (elem) {
            module.fcBookingEngineSuggest().init(elem, { whiteLabel: 'lastminute', searchUrl: 'https://ferien.lastminute.ch/api/keywords?' });
        });
    }
}

async function fastbookingLoader (lang: string): Promise<void> {
    const fastbookingForms = document.querySelectorAll('.tx-knfastbooking form');
    if (fastbookingForms.length > 0) {
        const module = await import(/* webpackChunkName: "fastbooking" */ './modules/fastbooking');
        [].forEach.call(fastbookingForms, function (formElem) {
            module.fastbookingComponent().init(formElem, { }, lang);
        });
    }
}

async function splideLoader (): Promise<void> {
    const splideElems = document.getElementsByClassName('splide');
    if (splideElems.length > 0) {
        await import(/* webpackChunkName: "splide" */ './modules/splide');
    }
}

function workOnDom (): void {
    window.siteHelper = new SiteHelper();
    window.siteHelper.handleAlertBar('alertBar', 'alertBarCloseLink');
    const lang = window.siteHelper.getLanguage();

    // dynamic modules
    promoBoxComponentLoader().then(() => null).catch(() => null);
    fcSuggestLoader().then(() => null).catch(() => null);
    fastbookingLoader(lang).then(() => null).catch(() => null);
    splideLoader().then(() => null).catch(() => null);
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => workOnDom());
} else {
    workOnDom();
}

interface SettingsOptions {
    [key: string]: string|number
}

export class SiteHelper {
    private readonly localStorageKey = 'lsk';
    private readonly sessionStorageKey = 'ssk';
    private readonly defaultLang = 'de';

    protected siteSettings: SettingsOptions;
    protected sessionSettings: SettingsOptions;

    constructor () {
        this.siteSettings = {

        };
        this.sessionSettings = {
            alertBarClosed: 0
        };
        this.loadSettings();
    }

    handleAlertBar (alertBarId: string, alertBarCloseLinkId: string) : void {
        // show alert bar if not already closed
        if (this.sessionSettings.alertBarClosed !== 1) {
            this.showAlertBar(alertBarId);
        }
        // close alert bar listener
        const alertBarCloseLink = document.getElementById(alertBarCloseLinkId);
        alertBarCloseLink?.addEventListener('click', () => {
            this.removeAlertBar(alertBarId);
            this.saveToSessionSettings('alertBarClosed', 1);
        });
    }

    handleSearchBarFocus (searchBarCollapseId: string, searchInputId: string) : void {
        const searchBarCollapsible = document.getElementById(searchBarCollapseId);
        if (searchBarCollapsible) {
            searchBarCollapsible.addEventListener('shown.bs.collapse', () => {
                const searchBarInputField = document.getElementById(searchInputId);
                if (searchBarInputField) {
                    searchBarInputField.focus();
                }
            });
        }
    }

    getLanguage (): string {
        const [html] = document.getElementsByTagName('html');
        if (html) {
            const langAttrib = html.getAttribute('lang');
            if (langAttrib) {
                return langAttrib.substring(0, 2);
            }
        }
        return this.defaultLang;
    }

    showAlertBar (elementId: string) : void {
        if (this.showElement(elementId)) {
            document.body.classList.add('alert-bar-shown');
        }
    }

    removeAlertBar (elementId: string) : void {
        this.removeElement(elementId);
        document.body.classList.remove('alert-bar-shown');
    }

    showElement (elementId: string) : boolean {
        const elem = document.getElementById(elementId);
        if (elem !== null) {
            elem.style.display = 'block';
            return true;
        }
        return false;
    }

    removeElement (elementId: string) : boolean {
        const elem = document.getElementById(elementId);
        if (elem && elem.parentNode) {
            elem.parentNode.removeChild(elem);
            return true;
        }
        return false;
    }

    storeInSiteSettings (name: string, value: string|number) : void {
        this.siteSettings[name] = value;
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.siteSettings));
    }

    saveToSessionSettings (name: string, value: string|number) : void {
        this.sessionSettings[name] = value;
        sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(this.sessionSettings));
    }

    loadSettings () : void {
        const lsSettings = localStorage.getItem(this.localStorageKey);
        if (lsSettings !== null) {
            this.siteSettings = this.getSettingsOption(lsSettings);
        }
        const ssSettings = sessionStorage.getItem(this.sessionStorageKey);
        if (ssSettings !== null) {
            this.sessionSettings = this.getSettingsOption(ssSettings);
        }
    }

    getSettingsOption (str: string): SettingsOptions {
        return JSON.parse(str) as SettingsOptions;
    }
}

// eslint-disable-next-line import/no-unassigned-import, import/no-relative-parent-imports
import '../../scss/modules/megamenu.scss';

function workOnDom (): void {
    const lang = document.documentElement.lang.substring(0, 2);
    const megaMenuPageType = 8048;
    let megaMenuUrl = '/megamenu';
    if (lang === 'fr') {
        megaMenuUrl = '/fr/megamenu';
    }

    const megaMenuNavItemList = document.querySelectorAll<HTMLLIElement>('.dropdown-mega');

    if (megaMenuNavItemList) {
        const navBarContainer = <HTMLDivElement>document.querySelector('.header-toprow-container');
        const megaMenuContainer = <HTMLDivElement>document.querySelector('.megamenu-container .container');

        if (megaMenuContainer) {
            const megaMenuBodyOverlay = document.createElement('div');
            megaMenuBodyOverlay.classList.add('megamenu-overlay');
            document.body.insertBefore(megaMenuBodyOverlay, document.body.firstChild);

            let currentMegaMenuItem = '';

            megaMenuNavItemList.forEach((navItem) => {
                const pid = <string>navItem.dataset.pid;
                fetch(`${megaMenuUrl}?type=${megaMenuPageType}&mpid=${pid}`, {
                    method: 'GET'
                }).then((response): Promise<string> => {
                    if (response.status === 200) {
                        return response.text();
                    } else {
                        return new Promise((resolve) => { resolve(''); });
                    }
                }).then((content) => {
                    if (content !== '') {
                        const megaMenuWrap = document.createElement('div');
                        megaMenuWrap.classList.add('megamenu-item');
                        megaMenuWrap.classList.add('collapse');
                        megaMenuWrap.id = `mMenuItem${pid}`;
                        megaMenuWrap.dataset.bsParent = '.megamenu-container';
                        megaMenuWrap.innerHTML = content;
                        megaMenuContainer.append(megaMenuWrap);

                        megaMenuWrap.addEventListener('hide.bs.collapse', function (this: HTMLDivElement) {
                            if (currentMegaMenuItem === this.id) {
                                megaMenuBodyOverlay.style.display = 'none';
                                navBarContainer.classList.remove('active');
                                currentMegaMenuItem = '';
                            }
                        });
                        megaMenuWrap.addEventListener('show.bs.collapse', function (this: HTMLDivElement) {
                            megaMenuBodyOverlay.style.display = 'block';
                            navBarContainer.classList.add('active');
                            currentMegaMenuItem = this.id;
                        });
                    }
                    return '';
                }).catch(() => { return ''; });
            });

            megaMenuBodyOverlay.addEventListener('click', () => {
                const megaMenuItems = document.querySelectorAll<HTMLDivElement>('.megamenu-item');
                if (megaMenuItems) {
                    megaMenuItems.forEach((elem) => {
                        const bsCollapse = window.bootstrap.Collapse.getInstance(elem);
                        if (bsCollapse) {
                            bsCollapse.hide();
                        }
                    });
                }
                megaMenuBodyOverlay.style.display = 'none';
                navBarContainer.classList.remove('active');
            });

            const dropdownMenuService = document.querySelector('.service-dropdown-trigger');
            if (dropdownMenuService) {
                dropdownMenuService.addEventListener('show.bs.dropdown', () => {
                    const megaMenuItems = document.querySelectorAll<HTMLDivElement>('.megamenu-item');
                    if (megaMenuItems) {
                        megaMenuItems.forEach((elem) => {
                            const bsCollapse = window.bootstrap.Collapse.getInstance(elem);
                            if (bsCollapse) {
                                bsCollapse.hide();
                            }
                        });
                    }
                    megaMenuBodyOverlay.style.display = 'none';
                    navBarContainer.classList.remove('active');
                });
            }
        }
    }
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => workOnDom());
} else {
    workOnDom();
}

if (window.ucDps) {
    window.ucDps.youtubeVideo = false;
} else {
    window.ucDps = { youtubeVideo: false };
}

window.addEventListener('onConsentStatusChange', (event: Event) => {
    const customEvent = <CustomEvent>event;
    const dpsYoutubeVideo = 'YouTube Video';

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (customEvent.detail.event && customEvent.detail.event === 'consent_status') {
        if (dpsYoutubeVideo in customEvent.detail) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            window.ucDps.youtubeVideo = !!customEvent.detail[dpsYoutubeVideo];
        }

        let ytConsentBlockedMsg = 'Das Video wurde aufgrund der gewählten <a onclick="UC_UI.showSecondLayer();">Datenschutzeinstellungen</a> blockiert.';
        if (document.documentElement.lang.startsWith('fr')) {
            ytConsentBlockedMsg = 'La vidéo a été bloquée en raison des <a onclick="UC_UI.showSecondLayer();">paramètres de confidentialité</a> choisis.';
        }

        // enable YT iframes
        const ytIframes = document.querySelectorAll('iframe.yt-video, iframe.video-embed-item');
        if (ytIframes.length) {
            ytIframes.forEach((elem) => {
                const iFrameElem = <HTMLIFrameElement>elem;
                if (window.ucDps.youtubeVideo) {
                    if (iFrameElem.dataset.src) {
                        const iFrameContainer = iFrameElem.parentElement;
                        if (iFrameContainer) {
                            const noConsentInfo = iFrameContainer.querySelector('.no-consent');
                            if (noConsentInfo) {
                                noConsentInfo.remove();
                            }
                            iFrameContainer.classList.remove('no-consent');
                            iFrameElem.src = iFrameElem.dataset.src;
                        }
                    }
                } else {
                    if (!iFrameElem.dataset || !iFrameElem.dataset.src) {
                        iFrameElem.dataset.src = iFrameElem.src;
                    }
                    iFrameElem.src = '';

                    const noConsentInfo = document.createElement('div');
                    noConsentInfo.innerHTML = ytConsentBlockedMsg;
                    noConsentInfo.className = 'no-consent-info';
                    if (iFrameElem.parentElement) {
                        iFrameElem.parentElement.append(noConsentInfo);
                        iFrameElem.parentElement.classList.add('no-consent');
                    }
                    // iFrameElem.classList.add('no-consent');
                }
            });
        }
    }
});

export {};

if (window.ucDps) {
    window.ucDps.googleMaps = false;
} else {
    window.ucDps = { googleMaps: false };
}

window.addEventListener('onConsentStatusChange', (event: Event) => {
    const customEvent = <CustomEvent>event;
    const dpsGoogleMaps = 'Google Maps';

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (customEvent.detail.event && customEvent.detail.event === 'consent_status') {
        if (dpsGoogleMaps in customEvent.detail) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            window.ucDps.googleMaps = !!customEvent.detail[dpsGoogleMaps];
        }
    }
});

export {};
